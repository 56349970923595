import React from 'react'
import {useTranslation} from "react-i18next"
import {ColumnCenter, SpaceBox, Warning} from "../Containers"
import {TextLarge} from "../Texts"
import {LoadingButtonRounded} from "../fields/Common"
import BoxTrans from "./BoxTrans"
import PinInput from "react-pin-input"
import {TransactionModel} from "../../models/transactionModel"
import IconCheck from "@mui/icons-material/Check"
import IconCopy from "@mui/icons-material/ContentCopy"
import IconButton from '@mui/material/IconButton'
import styled from "@emotion/styled";
import Snackbar from '@mui/material/Snackbar'


interface Props {
  header: string
  title: string
  transaction: TransactionModel
}

const BoxTransCode: React.FunctionComponent<Props> =  ({header, title, transaction}) => {

  const pinLength = 4
  const { t } = useTranslation()
  const exchangeCode = transaction?.exchangeCode || ""
  const exchangeCodeUsed = transaction.counterpartInsertedEcode || false

  const [showSnackbar, setShowSnackbar] = React.useState(false);


  const copyCodeToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(exchangeCode)
      setShowSnackbar(true)
    } catch (error) {
      alert('Error copying to clipboard:'+ error);
    }
  }

  const hideSnackbar = () => {
    setShowSnackbar(false);
  }

  return (
    <BoxTrans header={header}
              title={title}
              background={"#F4F4F4"}>
      <ColumnCenter>
        <ColumnCenter>
          <PinWithCopy >
            <PinInput
              disabled={true}
              type={"custom"}
              regexCriteria={RegExp("[\\s\\S]+")}
              length={pinLength}
              initialValue={exchangeCode}/>

              <CopyButton color="primary" aria-label="copy to cliboard" onClick={copyCodeToClipboard}>
                <IconCopy />

                <Snackbar
                  anchorOrigin={{ vertical:"bottom", horizontal: "left"}}
                  autoHideDuration={3000}
                  open={showSnackbar}
                  onClose={hideSnackbar}
                  message={t("transaction.text.code.clipboard")}/>

              </CopyButton>
            
          </PinWithCopy>
          <SpaceBox size={16}/>
          <TextLarge $color={"#001964"}>{t("transaction.complete.secret.code.text")}</TextLarge>
          <SpaceBox size={8}/>
        </ColumnCenter>

        <LoadingButtonRounded variant="contained"
                              disabled={true}
                              width={"160px"}
                              startIcon={exchangeCodeUsed && <IconCheck/>}
                              sx={{visibility: exchangeCodeUsed ? "visible" : "hidden"}}>
          {t("transaction.label.activated")}
        </LoadingButtonRounded>

        <Warning style={{visibility: "hidden"}}/>

      </ColumnCenter>
    </BoxTrans>
  )
}


const PinWithCopy = styled.div`
  position: relative;  
`;

const CopyButton = styled(IconButton)`
  position: absolute;
  top: 5px;
  right: -40px;
`;


export default BoxTransCode


