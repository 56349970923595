import React from 'react'
import {TextMedium, TextSmall} from "../Texts"
import {Box} from "@mui/material"
import {SpaceBox} from "../Containers"

export interface LabelValueProp {
  label: string
  value: string
}

const LabelValue: React.FunctionComponent<LabelValueProp> = ({label, value}) => {

  return (
    <Box mt={1}>
        <TextSmall $color={"#C0C0C0"}>{label}</TextSmall>
        <SpaceBox size={2}/>
        <TextMedium $color={"#001964"}>{value}</TextMedium>
      </Box>
  )
}

export default LabelValue