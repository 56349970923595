import http from "./http";
import {UserLogin} from "../models/user.models";

const BASE_URL = process.env.REACT_APP_AUTH_API_ENDPOINT;


const login = (data: UserLogin) => {
  return http.post("/login", data,{baseURL: BASE_URL});
};

const AuthService = {
  login
}

export default AuthService;