import React, {useState} from "react"
import {
  getProfileDataBirthDocument,
  getProfilePersonalResidence,
  ProfileDataBirthDocument,
  ProfileDataPersonalResidence,
  ProfileModel
} from "../lib/models/profile.models"
import {useTranslation} from "react-i18next"
import {PageLeft, MarginBox, Warning, HorizontalBox, Filler} from "../lib/components/Containers"
import {ButtonRounded} from "../lib/components/fields/Common"
import IconNext from "@mui/icons-material/ArrowForward"
import FormBirthDocument from "../lib/components/profiles/FormBirthDocument"
import {EditMode} from "../lib/models/constants.enum"
import Spinner from "../lib/components/Spinner"
import FormPersonalResidence from "../lib/components/profiles/FormPersonalResidence"
import {Step, StepLabel, Stepper} from "@mui/material"


interface Props {
  mode: EditMode
  profile: ProfileModel
  step: number,
  maxSteps: number
  onBack: () => void
  onNext: (profile: ProfileModel) => void
  saving: boolean
  errors: any
}

const FormProfileLandscape: React.FunctionComponent<Props> = ({mode, profile, step, maxSteps, onBack, onNext, saving, errors }) => {

  const { t } = useTranslation()
  const [formData, setFormData] = useState<ProfileModel>(profile)
  const [validity, setValidity] = useState<boolean[]>([false, false])


  const buttonBackVisible = step > -1

  const handleNext = () => {
    onNext(formData)
  }

  const handleBack = () => {
    onBack()
  }

  const onPersonalResidenceChanged = (data: ProfileDataPersonalResidence, valid: boolean) => {
    console.log("onPersonalResidenceChanged "+ valid +" -> "+ data.cf+" "+data.firstName+" "+data.lastName+" "+data.sex+" "+data.phone+" "+data.residenceCountry+" "+data.residenceCity+" "+data.residenceProvince+" "+data.residenceAddress+" "+data.residenceCAP+" "+data.acceptedTerms)
    setFormData( {...formData, ...data })
    setValidity([valid, validity[1]])
  }


  const onBirthDocumentChanged = (data: ProfileDataBirthDocument, valid: boolean) => {
    console.log("onBirthChanged "+ valid +" -> "+data.birthCountry+" "+data.birthCity+" "+data.birthProvince+" "+data.birthDate+" "+data.docType+" "+data.docNum+" "+data.docExpiryDate+" "+data.docPictureFrontFile+" "+data.docPictureBackFile)
    setFormData( {...formData, ...data })
    setValidity([validity[0], valid])
  }


  return (
    <PageLeft>
      <MarginBox width={"100%"} top={"30px"} bottom={"20px"}>
        <h1>{t(mode === EditMode.CREATE ? "profile.header.create" : "profile.header.update")}</h1>
        <Filler/>
        <Stepper sx={{minWidth:"200px", alignSelf:"end"}} activeStep={step}>
          {[...Array(maxSteps)].map((label, index) => {
            return (
              <Step key={index}>
                <StepLabel/>
              </Step>
            )
          })}
        </Stepper>
      </MarginBox>
      {step === 0 && (
        <FormPersonalResidence profile={getProfilePersonalResidence(profile)} onChanged={onPersonalResidenceChanged}/>
      )}
      {step === 1 && (
        <FormBirthDocument profile={getProfileDataBirthDocument(profile)} onChanged={onBirthDocumentChanged}/>
      )}

      <HorizontalBox sx={{alignSelf: "end", justifyContent: "center", marginTop:2, marginBottom:2, gap: 5}}>
        {!saving && (
          <>
          <ButtonRounded onClick={handleBack} variant="contained" color="secondary"
                       sx={{visibility: buttonBackVisible ? "visible" : "hidden"}}>
          {t("button.label.back")}
        </ButtonRounded>
        <ButtonRounded onClick={handleNext} variant="contained" endIcon={<IconNext />} disabled={!validity[step]}>
          {t("button.label.next")}
        </ButtonRounded>
          </>
        )}
        {saving && (
          <Spinner/>
        )}
      </HorizontalBox>

      { errors && (
        <Warning severity="warning">
            {errors}
        </Warning>
      )}

    </PageLeft>
  )
}


export default FormProfileLandscape