import React, {FunctionComponent, useState} from 'react';
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";
import {HorizontalBox, PageCenter, SpaceBox, PageLeft} from "../lib/components/Containers";
import {Alert, Step, StepLabel, Stepper, Toolbar, useMediaQuery} from "@mui/material";
import FormTransParty from "../lib/components/transactions/FormTransParty";
import FormTransType from "../lib/components/transactions/FormTransType";
import FormTransData from "../lib/components/transactions/FormTransData";
import {ProfileModel} from "../lib/models/profile.models";
import {TransactionPartyType, TransactionType, TransCreateData, TransCreateModel} from "../lib/models/transactionModel";
import IconNext from "@mui/icons-material/ArrowForward";
import {ButtonRounded, H1Center} from "../lib/components/fields/Common";
import FormTransUpload from "../lib/components/transactions/FormTransUpload";
import {useNavigate} from "react-router-dom";
import IconLogo from "../lib/images/svg/logo.svg";
import useSaveTransaction from "../lib/hooks/useSaveTransaction";
import Spinner from "../lib/components/Spinner";
import {updatedValuesAtIndex} from "../lib/utils/Functions";
import Link from "@mui/material/Link";
import {UrlsEnum} from "../enums/urls.enum";
import {LOGIN_TYPE} from "../Config";
import FormTransActor from "../lib/components/transactions/FormTransActor";


interface MultiPartyType {
  buyer: ProfileModel | null
  seller: ProfileModel | null
}


const TransactionCreate: FunctionComponent = () => {

  const onCreated = (transactionId: string) => {
    console.log("onCreated "+transactionId)
    setTransactionId(transactionId)
  }

  const { t } = useTranslation()
  const navigate = useNavigate()
  const portrait = useMediaQuery("(max-width:640px)")
  const {saveTransactions} = useSaveTransaction(onCreated)
  const {mutate: save, isPending: isSaving, isSuccess: isSaved, isError: isFailed, error } = saveTransactions
  const [step, setStep] = useState(0)
  const [partyType, setPartyType] = useState<MultiPartyType>({buyer: null, seller: null})
  const [transType, setTransType] = useState<TransactionType | null>(null)
  const [transData, setTransData] = useState<TransCreateData>({ licensePlates:"", price:"", currency:"EUR", iban:""})
  const [transFile, setTransFile] = useState<File | null>(null)
  const [transactionId, setTransactionId] = useState("")

  const [validity, setValidity] = useState<boolean[]>([false, false, false, false])

  const selling = partyType.seller !== null
  const buying = partyType.buyer !== null
  const maxSteps = selling ? 4 : 3
  const showPreviousButton =  !isSaving && !isSaved
  const isLastStep = step === maxSteps

  const createTransactions = () => {
    const transactions: TransCreateModel[] = []
    if (selling && partyType.seller?.userId) {
      transactions.push( {...transData, itemType: transType!.id, partyType: TransactionPartyType.seller, user: partyType.seller.userId, file: transFile!} )
    }
    if (buying && partyType.buyer?.userId) {
      let item = {...transData, itemType: transType!.id, partyType: TransactionPartyType.buyer, user: partyType.buyer.userId};
      delete (item.iban)
      transactions.push( item )
    }
    save(transactions)
  }

  const handleNext = () => {
    const next = step + 1
    setStep(next)
    if (next === maxSteps) {
      createTransactions()
    }
  }

  const handleBack = () => {
    if (step === 0) {
      navigate(-1)
    }
    setStep(step - 1)
  }

  const gotoTransactions = () => {
    navigate(UrlsEnum.TRANSACTIONS, {replace: true})
  }

  const gotoTransaction = () => {
    navigate(UrlsEnum.TRANSACTIONS+"/"+transactionId+"/view", {replace: true})
  }

  const setValid = (step: number, valid: boolean) => {
    setValidity(updatedValuesAtIndex(step, valid, validity))
}

  const onPartyChanged = (buyer: ProfileModel | null, seller: ProfileModel | null) => {
    setPartyType({ buyer: buyer, seller: seller})
    const valid = buyer != null || seller != null
    setValid(0, valid)
  }

  const onTypeChanged = (type: TransactionType | null) => {
    setTransType(type)
    const valid = type != null
    setValid(1, valid)
  }

  const onDataChanged = (data: TransCreateData, valid: boolean) => {
    setTransData(data)
    setValid(2, valid)
  }

  const onFileChanged = (file: File | null) => {
    const valid = file != null
    setTransFile(file)
    setValid(3, valid)
  }

  const stepItmes = [...Array(maxSteps)].map((label, index) => {
    return (
      <Step key={index}>
        <StepLabel/>
      </Step>
    )
  })


  return (

    <PageLeft>

      {step === maxSteps &&
        <PageCenter>
          <Logo src={IconLogo}/>
          <br/>
          {isSaving &&
              <Spinner fill={0}/>}

          {isFailed &&
            <Alert severity="warning">{error as any}</Alert>}

          {isSaved &&
            <>
              <h1>{t("transaction.text.created")}</h1>
              <br/>
              <Link variant={"body1"}
                    sx={{cursor: "pointer"}}
                    onClick={gotoTransaction}>
                {t("transaction.label.link")}
              </Link>
              <br/>
              <Link variant={"body1"}
                    sx={{cursor: "pointer"}}
                    onClick={gotoTransactions}>
                {t("transaction.text.complete")}
              </Link>
            </>}
      </PageCenter>}

      {step < maxSteps &&
        <>
          <Toolbar>
            <H1Center>{t("transaction.header.create")}</H1Center>
          </Toolbar>
          <SpaceBox size={24}/>
          <HorizontalBox sx={{alignSelf: "center"}}>
            <Stepper sx={{width: portrait ? "320px" : "550px"}} activeStep={step}>{stepItmes}</Stepper>
          </HorizontalBox>

          <SpaceBox size={16}/>

          {step === 0 && LOGIN_TYPE === "company" &&
            <FormTransParty
              buyer={partyType.buyer}
              seller={partyType.seller}
              onChanged={onPartyChanged}/>}

          {step === 0 && LOGIN_TYPE === "private" &&
            <FormTransActor
              buyer={partyType.buyer}
              seller={partyType.seller}
              onChanged={onPartyChanged}/>}

          {step === 1 &&
            <FormTransType
              transType={transType}
              onChanged={onTypeChanged}/>}

          {step === 2 && (
            <FormTransData
              showIban={selling}
              data={transData}
              onChanged={onDataChanged}/>)}

          {step === 3 && selling &&
            <FormTransUpload
              file={transFile}
              onChanged={onFileChanged}/>}
        </>}

      <Toolbar sx={{marginTop: "1rem", alignSelf: isLastStep ? "center" : "end", gap: portrait ? "1rem" : "10px" }}>
        <ButtonRounded variant="contained" color="secondary"
                       size={ portrait ? "medium" : "large"}
                       onClick={handleBack}
                       sx = {{ visibility: showPreviousButton ? "visible" : "hidden"}}>
          {t("button.label.back")}
        </ButtonRounded>
        <ButtonRounded variant="contained"
                       endIcon={<IconNext/>}
                       onClick={handleNext}
                       size={ portrait ? "medium" : "large"}
                       disabled={!validity[step]}
                       sx = {{ display: isLastStep ? "none" : "inline-flex"}}>
          {t("button.label.next")}
        </ButtonRounded>
      </Toolbar>
    </PageLeft>
  )

}

export default TransactionCreate


const Logo = styled.img`
  height: 170px;
`;
