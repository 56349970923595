import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from "react-i18next"
import {
  CenterFull,
  PageLeft,
  SpaceBox,
  Warning,
  BorderBox,
  RowFlex,
  BoxSection,
} from "../lib/components/Containers"

import {useNavigate, useParams} from "react-router-dom"
import Spinner from "../lib/components/Spinner"
import TopBar from "../components/TopBar"
import {IconButton, Box} from "@mui/material"
import IconBack from "../lib/images/svg/back.svg"
import IconRefresh from "@mui/icons-material/Refresh"

import BoxTransState from "../lib/components/transactions/BoxTransState";
import LabelValue from "../lib/components/transactions/LabelValue";
import useViewTransaction from "../lib/hooks/useViewTransaction";
import BoxTransUser from "../lib/components/transactions/BoxTransUser";
import PanelTransPayment from "../lib/components/transactions/PanelTransPayment";
import {isBuyerTransaction, isSellerTransaction, TransactionTypes} from "../lib/models/transactionModel";
import BoxTransUpload from "../lib/components/transactions/BoxTransUpload";
import useSaveTransaction, {ExchangeCodeParams} from "../lib/hooks/useSaveTransaction";
import PanelTransCodes from "../lib/components/transactions/PanelTransCodes";
import {decodeCurrency} from "../lib/utils/Functions";
import PanelTransConfirm from "../lib/components/transactions/PanelTransConfirm";
import useCompany from "../lib/hooks/useCompany";
import {PaymentModel} from "../lib/models/user.models";
import useFee from "../lib/hooks/useFee";


const decodeType = (transType: string) => {
  return TransactionTypes.filter( (item) => item.id === transType)[0]?.i18n
}

const TransactionView: FunctionComponent = () => {

  const onSaved = () => {
    reload()
  }

  const navigate = useNavigate()
  const { t } = useTranslation()
  const [propertyProofFile, setPropertyProofFile] = useState<File | null>(null)
  const [propertyTransferFile, setPropertyTransferFile] = useState<File | null>(null)
  const {transactionId} = useParams();
  const {transaction, matchedTransaction,
    buyer, seller, operatorManaged
  } = useViewTransaction(transactionId!)
  const {savePropertyProof, savePropertyTransfer,
    saveExchangeCode,  saveExchangeCodes
  } = useSaveTransaction(onSaved)
  const {info: companyInfo} = useCompany(operatorManaged)
  const {fee: paymentFee} = useFee(transaction.data?.price)
  const [payment, setPayment] = useState<PaymentModel>()


  const reload = () => {
    navigate(0)
  }

  if (savePropertyProof.isSuccess) {
    reload()
  }

  const {data: currentTransaction} = transaction
  const sellerTransaction = isSellerTransaction(currentTransaction) ? currentTransaction : isSellerTransaction(matchedTransaction.data) ? matchedTransaction.data : undefined
  const buyerTransaction = isBuyerTransaction(currentTransaction) ? currentTransaction : isBuyerTransaction(matchedTransaction.data) ? matchedTransaction.data : undefined

  useEffect( () => {
    if (companyInfo.data && paymentFee.data) {
      setPayment({iban: companyInfo.data.iban, recipient: companyInfo.data.legalName, amount: paymentFee.data.amount})
    }
  }, [companyInfo.data, paymentFee.data])

  const isReady = (): boolean => {
    if (operatorManaged === true) {
      return sellerTransaction !== undefined && buyerTransaction !== undefined
    } else if (operatorManaged === false) {
      return currentTransaction !== undefined
    }
    return false
  }

  const canShowUploadPropertyProof = (): boolean => {
    let transaction
    if (operatorManaged === true) {
      transaction = sellerTransaction
    } else if (operatorManaged === false && isSellerTransaction(currentTransaction)) {
      transaction = currentTransaction
    }
    return transaction?.state === 1 && !transaction.propertyProofURL
  }
  const canShowPayment = (): boolean => {
    let transaction
    if (operatorManaged === true) {
      transaction = sellerTransaction
    } else if (operatorManaged === false && isBuyerTransaction(currentTransaction)) {
      transaction = currentTransaction
    }
    return transaction?.state === 3 && payment !== undefined;
  }
  const canShowConfirm = (): boolean =>  operatorManaged === true && sellerTransaction?.state === 4

  const canShowExchange = (): boolean => operatorManaged === false && (currentTransaction?.state === 4 || currentTransaction?.state === 5)

  const canAddParty = (): boolean => {
    if (operatorManaged === true) {
      return sellerTransaction === undefined || buyerTransaction === undefined
    }
    return false
  }


  const goBack = () => {
    navigate(-1)
  }

  const submitPropertyProof = () => {
    if (sellerTransaction) {
      savePropertyProof.mutate({transactionId: sellerTransaction.id, file: propertyProofFile!, userId: sellerTransaction.user})
    }
  }

  const submitPropertyTransfer = () => {
    if (sellerTransaction) {
      savePropertyTransfer.mutate({transactionId: sellerTransaction.id, file: propertyTransferFile!, userId: sellerTransaction.user})
    }
  }

  const submitPin = (pin: string) => {
    if (transaction?.data) {
      saveExchangeCode.mutate({transactionId: currentTransaction!.id, code: pin})
    }
  }

  const submitPins = () => {
    if (sellerTransaction?.exchangeCode && buyerTransaction?.exchangeCode) {
      const params: ExchangeCodeParams[] = []
      params.push({transactionId: sellerTransaction.id, code: buyerTransaction.exchangeCode})
      params.push({transactionId: buyerTransaction.id, code: sellerTransaction.exchangeCode})
      saveExchangeCodes.mutate(params)
    }
  }


  return (

    <PageLeft>
      {transaction.isSuccess && currentTransaction &&
        <>
          <TopBar justify={"space-between"} icon={<IconButton onClick={goBack}><img alt="back" src={IconBack}/></IconButton>}
                title={t("transaction.header.detail")+" "+currentTransaction.licensePlates}>
              <IconButton onClick={reload}><IconRefresh/></IconButton>
          </TopBar>

          <BorderBox padding={"20px"}>
            <BoxTransState state={currentTransaction.state} partyType={currentTransaction.partyType}/>
            <SpaceBox size={12}/>
            <RowFlex>
              <BoxSection>
                <h3>{t("transaction.partyType.buyer")} {isBuyerTransaction(currentTransaction) && "*"}</h3>
                <BoxTransUser label={t("profile.text.name")}
                              profile={buyer.data}
                              identifier={buyerTransaction?.id}
                              isSuccess={buyer.isFetched}
                              isPending={buyer.isFetching}
                              error={buyer.error}/>
              </BoxSection>
              <BoxSection>
                <h3>{t("transaction.partyType.seller")} {isSellerTransaction(currentTransaction) && "*"}</h3>
                <RowFlex>
                  <BoxTransUser label={t("profile.text.name")}
                                profile={seller.data}
                                identifier={sellerTransaction?.id}
                                isSuccess={seller.isFetched}
                                isPending={seller.isFetching}
                                error={seller.error}/>
                </RowFlex>
              </BoxSection>
              <BoxSection>
                <h3>{t("transaction.header.view")}</h3>
                <RowFlex>
                  <Box>
                    <LabelValue label={t("transaction.label.subject")} value={t(decodeType(currentTransaction.itemType))}/>
                    <LabelValue label={t("transaction.label.currency")} value={decodeCurrency(currentTransaction.currency)}/>
                  </Box>
                  <SpaceBox size={16}/>
                  <Box>
                    <LabelValue label={t("transaction.label.plate")} value={t(currentTransaction.licensePlates)}/>
                    <LabelValue label={t("transaction.label.price.long")} value={currentTransaction.price.toLocaleString()}/>
                  </Box>
                </RowFlex>
              </BoxSection>
            </RowFlex>
          </BorderBox>



          { canShowUploadPropertyProof() &&
            <BoxTransUpload header={t("transaction.resume.title")}
                            title={t("transaction.complete.property.proof.text")}
                            onUploadFileChanged={setPropertyProofFile}
                            onUploadFileSubmit={submitPropertyProof}
                            uploading={savePropertyProof.isPending}
                            uploadError={savePropertyProof.error}
                            uploadFile={propertyProofFile}
                            background={"#F4F4F4"}/>}

          { canAddParty() &&
            <></>
          }


          { isReady() &&
            <>

            { canShowPayment() &&
              <PanelTransPayment transaction={sellerTransaction!}
                                 payment={payment!}/>}

            { canShowExchange() &&
              <PanelTransCodes transaction={currentTransaction}

                               onPinSubmit={submitPin}
                               pinPosting={saveExchangeCode.isPending}
                               pinSuccess={saveExchangeCode.isSuccess}
                               pinError={saveExchangeCode.error}

                               onUploadFileChanged={setPropertyTransferFile}
                               onUploadFileSubmit={submitPropertyTransfer}
                               uploading={savePropertyTransfer.isPending}
                               uploadError={savePropertyTransfer.error}
                               uploadFile={propertyTransferFile}/>}

            { canShowConfirm() &&
              <PanelTransConfirm transaction={sellerTransaction!}

                                 onPinSubmit={submitPins}
                                 pinPosting={saveExchangeCodes.isPending}
                                 pinSuccess={saveExchangeCodes.isSuccess}
                                 pinError={saveExchangeCodes.error}

                                 onUploadFileChanged={setPropertyTransferFile}
                                 onUploadFileSubmit={submitPropertyTransfer}
                                 uploading={savePropertyTransfer.isPending}
                                 uploadError={savePropertyTransfer.error}
                                 uploadFile={propertyTransferFile}/>}
            </>}

        </>}


      {transaction.isPending &&
        <CenterFull>
          <Spinner />
        </CenterFull>}

      {transaction.isError &&
        <Warning severity="warning">{transaction["error"]}</Warning>}

    </PageLeft>
  )

}

export default TransactionView


