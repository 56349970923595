import React, {FunctionComponent, useState} from "react"
import useListProfiles from "../lib/hooks/useListProfiles"
import Spinner from "../lib/components/Spinner"
import {useNavigate} from "react-router-dom"
import {UrlsEnum} from "../enums/urls.enum"
import {ButtonRounded} from "../lib/components/fields/Common"
import IconAdd from "@mui/icons-material/Add"
import {TableBody} from "@mui/material"
import {useTranslation} from "react-i18next"
import {CenterFull, PageList, TableData, TableScroll, ToolbarWide, Warning} from "../lib/components/Containers"
import TopBar from "../components/TopBar"
import RowProfile from "../lib/components/profiles/RowProfile"
import {ProfileModel} from "../lib/models/profile.models"
import {useQueryClient} from "@tanstack/react-query"
import {QueryMode} from "../lib/models/constants.enum"

const ProfileList: FunctionComponent = () => {

  const queryClient = useQueryClient()
  const { data: profiles, isSuccess, isPending, isError, error  } = useListProfiles(QueryMode.OPERATOR)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [filterName, setFilterName] = useState("")

  const predicateFilterName = (profile: ProfileModel) => {
    return filterName === "" ? true : (profile.firstName && profile.firstName.toUpperCase().indexOf(filterName.toUpperCase()) > -1) || (profile.lastName && profile.lastName.toUpperCase().indexOf(filterName.toUpperCase()) > 1)
  }


  const gotoCreateProfile = () => {
    navigate(UrlsEnum.PROFILES_CREATE)
  }

  const gotoEditProfile = (profile: ProfileModel) => {
    queryClient.removeQueries({ queryKey: ["profile", profile.id, QueryMode.OPERATOR] })
    navigate(UrlsEnum.PROFILES+"/"+profile.id+"/edit")
  }

  const profileItems = profiles && profiles.filter(predicateFilterName).map((profile) => {
    return (
      <RowProfile key={profile.id} profile={profile} onAction={gotoEditProfile}/>
    )})


  return (
    <PageList>

      <TopBar title={t("profile.header.list")}
              justify={"space-between"}
              searchEnabled={profiles && profiles.length > 0}
              searchPlaceholder={t("profile.text.name")}
              onSearchTextChanged={setFilterName}>
      </TopBar>

      {isSuccess &&
      <>
        {profiles.length === 0 &&
        <Warning severity="info">{t("profile.text.empty")}</Warning>}

        <TableScroll elevation={0}>
          <TableData>
            <TableBody>
              { profileItems }
            </TableBody>
          </TableData>
        </TableScroll>


        <ToolbarWide justify={"end"}>
          <ButtonRounded onClick={gotoCreateProfile} variant="contained" endIcon={<IconAdd />}>
            {t("profile.header.create")}
          </ButtonRounded>
        </ToolbarWide>

      </>}

      {isPending &&
        <CenterFull>
          <Spinner />
        </CenterFull>}

      {isError &&
       <Warning severity="warning">{error}</Warning>}

    </PageList>
  )


}

export default ProfileList