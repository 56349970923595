import React, {useContext} from "react"
import styled from "@emotion/styled"
import {useTranslation} from "react-i18next"
import {useMatch, useNavigate} from "react-router-dom"
import {UrlsEnum} from "../../enums/urls.enum"
import {ToolbarProps} from "@mui/material/Toolbar"
import {Banner} from "./Banner"
import {ButtonRounded} from "./fields/Common"
import {AuthContext} from "../AuthContext"

import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import IconMenu from "@mui/icons-material/Menu"
import IconMoreVert from "@mui/icons-material/MoreVert"
import IconLogout from "@mui/icons-material/Logout"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import {TextMedium} from "./Texts";
import {HorizontalBox, SpaceBox} from "./Containers";
import {LabelValueProp} from "./transactions/LabelValue";
import Link from "@mui/material/Link";


export const matchCurrentRoute = (route: LabelValueProp) => {
  const path = window.location.pathname
  return (path.indexOf(route.value) > -1) || (path === UrlsEnum.ROOT && route.value === UrlsEnum.INITIAL)
}

interface Props extends ToolbarProps {
  authenticated: boolean,
  routes?: LabelValueProp[]
}

const Header: React.FunctionComponent<Props> = ({authenticated = false, routes}) => {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const routeLogin = useMatch(UrlsEnum.LOGIN)

  const authContext = useContext(AuthContext)
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const [anchorElLogout, setAnchorElLogout] = React.useState<null | HTMLElement>(null)

  if (!authenticated || routeLogin) {
    return (<></>)
  }

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleOpenLogoutMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElLogout(event.currentTarget)

  const handleCloseLogoutMenu = () => setAnchorElLogout(null)

  const gotoHome = () => navigate(UrlsEnum.ROOT)

  const gotoHeaderRoute = (route: LabelValueProp) => navigate(route.value)

  const logout = () => {
    handleCloseLogoutMenu()
    authContext.clearAuthentication()
    navigate(UrlsEnum.LOGIN)
  }

  return (
    <>
      <AppBar position="static" color={"transparent"} sx={{boxShadow:"none", justifyContent: "center"}}>
        <HorizontalBox>
          <Bar disableGutters sx={{padding: { xs: "15px 5px", sm: "15px 30px"}}}>

            { /* desktop */}
            <Box sx={{ display: { xs: "none", md: "flex"}, justifyContent: "flex-start", mr: 1 }}>
              <Banner onClick={gotoHome}/>
            </Box>
            <Box sx={{ display: { xs: "none", md: "flex"}, justifyContent: "center" , flex: 1}}>
              { routes &&
                <Links>
                  {
                  routes.map( (route, index) => {
                    const selected = matchCurrentRoute(route)
                    return <LinkCustom key={index} selected={selected}
                      variant={selected ? "body1" : "body2"}
                      onClick={ () => gotoHeaderRoute(route) }>
                      {t(route.label)}
                    </LinkCustom>
                  }).flatMap((element, index) => [<SpaceBox key={"space"+index}  size={30}/>, element]).slice(1) }
              </Links>}
            </Box>

            <Box sx={{ display: { xs: "none", md: "flex"}, justifyContent: "flex-end", textAlign:"end", ml:2 }}>
              <ButtonRounded size="medium"
                             onClick={logout}
                             variant="contained"
                             color="secondary">
                {t("button.label.logout")}
              </ButtonRounded>
            </Box>

            { /* phone */}
            <Box sx={{ display: { xs: "flex", md: "none" }, justifyContent: "flex-start" }}>
              <IconButton size="large"
                          aria-label="menu"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onClick={handleOpenNavMenu}
                          color="primary">
                <IconMenu />
              </IconButton>
                
            </Box>

            <Box sx={{ display: { xs: "flex", md: "none" }, flex: 1, justifyContent: "center" }}>
              <Banner onClick={gotoHome}/>
            </Box>

            <Box sx={{ display: { xs: "flex", md: "none" }, justifyContent: "flex-end", textAlign:"end"}}>
              <IconButton size="large"
                          aria-label="menu logout"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onClick={handleOpenLogoutMenu}
                          color="primary">
                <IconMoreVert />
              </IconButton>
            </Box>

          </Bar>
        </HorizontalBox>
      </AppBar>
      <Line/>

      { routes &&
        <Menu id={"menu-header"}
              open={Boolean(anchorElNav)}
              anchorEl={anchorElNav}
              onClose={handleCloseNavMenu}
              keepMounted
              anchorOrigin={{vertical: "bottom", horizontal: "left"}}
              transformOrigin={{vertical: "top", horizontal: "left"}}>

          { routes.map( (route, index) => {
            const selected = matchCurrentRoute(route)
            return <MenuItem key={"menu"+index} onClick={ () => gotoHeaderRoute(route) }>
              <TextMedium $color={selected ? "#2E62FE": "#000"}>{t(route.label)}</TextMedium>
            </MenuItem>
            })}
        </Menu>
      }

      <Menu id={"menu-logout"}
            open={Boolean(anchorElLogout)}
            anchorEl={anchorElLogout}
            onClose={handleCloseLogoutMenu}
            keepMounted
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}
            transformOrigin={{vertical: 'top', horizontal: 'right'}}>
        <MenuItem onClick={logout}>
          <IconButton size="large" color="primary">
            <IconLogout />
          </IconButton>
          <TextMedium $color={"#2E62FE"}>Logout</TextMedium>
        </MenuItem>
      </Menu>


    </>
  )
}

export default Header


const Bar = styled(Toolbar)`
  max-width: var(--max-header-width);
  width: 100%;
  height: 90px;
  margin: 0 auto;
  flex-grow: 1;
  justify-content: space-between;
`

const Line  = styled.hr`
  border: 1px solid var(--color-grigio-chiaro);
  width: 100%;
  margin: 0;
`

const Links = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const LinkCustom  = styled(Link)<{selected: boolean}>`
  cursor: pointer;
  margin: 5px 0;
  ${props => props.selected && `
   font-weight: bold;
   color:  #2E62FE;
   underline: hover;
  `}
  ${props => !props.selected && `
   color:  #000;
   underline: always;
  `}
`